import React from "react";
import { Header, Footer } from "../../shared";
import { MainContext } from "../../../App";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Testimonials from "../landing/testimonials/Testimonials";
import axios from "axios";

const SuccessStories = () => {
  const [forgotModal, setForgotModal] = React.useState(false);
  const [signUp, setSignUp] = React.useState(false);
  const [showTermsModal, setShowTermsModal] = React.useState(false);
  const [showSupportModal, setShowSupportModal] = React.useState(false);
  const [successRate, setSuccessRate] = React.useState(67);
  const context = React.useContext(MainContext);
  const is_2fa = localStorage.getItem("is_2fa") === "true" ? true : false;

  const navigate = useNavigate();
  const currentURL = window.location.pathname;

  const successStories = [
    "Arrowstreet Capital",
    "Balyasny Asset Management",
    "Barclays",
    "BNP Paribas",
    "Capstone Investment",
    "CTC",
    "Cubist",
    "IMC Trading",
    "Millennium Management",
    "Point 72 Asset Management",
    "RBC Capital Market",
    "Seven Eight Capital",
    "Squarepoint Capital",
    "Verition",
    "Virtu Financial",
  ];

  const loadSuccessRate = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_QA_API}/success-story`
    );
    setSuccessRate(data.success_rate);
  }

  useEffect(() => {
    loadSuccessRate();
  }, [])

  useEffect(() => {
    if (is_2fa && !context.is2faVerified && context.login.isLoggedIn) {
      if (context.isNewDevice === false) {
        context.setIs2faVerified(true);
        localStorage.setItem("is_2fa_verified", true);
        navigate(context.isVantage === true ? "/linux" : "/statistics");
      } else {
        if (context.isLoginLimited === true) {
          context.setLoginLimitedModal(true);
          localStorage.clear();
          context.login.setIsLoggedIn(false);
        } else {
          context.setVerifyOTPModal(true);
        }
      }
    }
  }, [currentURL, context.login.isLoggedIn]);

  return (
    <>
      <div className="">
        <Header
          className="relative"
          login={context.login}
          loginModal={context.loginModal}
          setLoginModal={context.setLoginModal}
          forgotModal={forgotModal}
          setForgotModal={setForgotModal}
          signUp={signUp}
          setSignUp={setSignUp}
          verifyOTPModal={context.verifyOTPModal}
          setVerifyOTPModal={context.setVerifyOTPModal}
          LoginLimitedModal={context.isLoginLimited}
          setLoginLimitedModal={context.setLoginLimitedModal}
          showTermsModal={showTermsModal}
          setShowTermsModal={setShowTermsModal}
          showSupportModal={showSupportModal}
          setShowSupportModal={setShowSupportModal}
        />
      </div>
      <div className="flex flex-col article-section px-8">
        <div className="py-12 text-gray-g1 flex flex-col gap-1"></div>
        <div className="text-2xl font-bold">
          <a href="/success_stories">Success Stories</a>
        </div>
        <div className="text-gray-g1 flex flex-col gap-1">
          <div className="my-8 text-center">
            <p>
              Our candidates have secured desirable research and asset
              management roles among:
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {successStories.map((company, index) => (
              <div
                key={index}
                className="bg-green-100 p-4 rounded-md shadow-md text-center"
              >
                <p className="text-lg font-bold">{company}</p>
              </div>
            ))}
          </div>
          <div className="mt-8 text-center">
            <p>
              {"As of Jan 31, 2024, over "}
              <span className="text-green-g1 text-2xl font-bold">
                {successRate}%
              </span>
              {" candidates who took a 3-6 month subscription of QuantFinancePrep have landed placements in the above companies."}
            </p>
          </div>
          <Testimonials />
        </div>
        
      </div>
      <Footer
        setShowTermsModal={setShowTermsModal}
        setShowSupportModal={setShowSupportModal}
      />
    </>
  );
};

export default SuccessStories;

import React, { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import Eye from "./images/Eye.svg";
import { MainContext } from "../../../../App";
import { useNavigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js/pure";
import TermsAndConditionsModal from "./TermsAndConditions";
import CancelSubscriptionConfirmModal from "./CancelSubscriptionConfirm";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LegalDisclaimerModal } from "../../../shared/header/Header";

export const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PK}`);

const Setting = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const email = localStorage.getItem("email");
  const userId = localStorage.getItem("userId");
  const [userInfo, setUserInfo] = useState({
    email: "",
    username: "",
    linkedin: "",
    job_cities: "",
    phone: "",
    key_words: "",
    block_email_notification: false
  });
  const [password, setPassword] = useState({
    newValue: "",
    confirmValue: "",
  });

  const is_edu = email.includes(".edu") && !email.includes("alumni");
  const initPrices = is_edu
    ? {
        "1month": 1035,
        "3months": 2587.5,
        "6months": 4140,
        "1month_bank": 1010,
        "3months_bank": 2525,
        "6months_bank": 4040,
        "year_special": 7245,
        "academic_special": 11385,
        "year_special_bank": 7070,
        "academic_special_bank": 11110,
      }
    : {
        "1month": 4140,
        "3months": 10350,
        "6months": 16560,
        "1month_bank": 4040,
        "3months_bank": 10100,
        "6months_bank": 16160,
        "year_special": 7245,
        "academic_special": 11385,
        "year_special_bank": 7070,
        "academic_special_bank": 11110,
      };
  const initPricesVantage = {
    "3month": 620,
    "3month_bank": 605, 
    "year": 1034,
    "year_bank": 1009,
  };
  const [error, setError] = useState(false);
  const [profileError, setProfileError] = useState({
    email: "",
    username: "",
    linkedin: "",
  });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [cardError, setCardError] = useState("");
  const [subscriptionType, setSubscriptionType] = useState(null);
  const [vantageSubscriptionType, setVantageSubscriptionType] = useState(null);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [isAcceptedTerms, setIsAcceptedTerms] = useState(false);
  const [isInEdit, setIsInEdit] = useState(false);
  const [showCancelSubscriptionModal, setShowCancelSubscriptionModal] =
    useState(false);
  const [priceOptions, setPriceOptions] = useState(initPrices);
  const [vantagePriceOptions, setVantagePriceOptions] =
    useState(initPricesVantage);
  const [checked, setChecked] = useState({
    "1month": false,
    "3months": false,
    "6months": false,
    "1month_bank": false,
    "3months_bank": false,
    "6months_bank": false,
    "year_special": false,
    "academic_special": false,
    "year_special_bank": false,
    "academic_special_bank": false,
  });
  const [vantageChecked, setVantageChecked] = useState({
    "3month": false,
    "3month_bank": false,
    "year": false,
    "year_bank": false,
  });
  const [selectedModule, setSelectedModule] = useState("");
  const [selectedModuleInEdit, setSelectedModuleInEdit] = useState("");

  const context = React.useContext(MainContext);
  const currentURL = window.location.pathname;
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Token ${token} `,
  };

  async function loadUserInfo() {
    try {
      const { data, status } = await axios.get(
        `${process.env.REACT_APP_QA_API}/user-profile/${userId}`,
        { headers }
      );
      if (status === 200) {
        if (is_edu && data.is_legal_edu === false) {
          context.setShowLegalDisclaimerModal(true);
        }
        setUserInfo(data);
        setSubscriptionType(data.subscription_type);
        setVantageSubscriptionType(data.vantage_subscription_type);
        context.setIsSubscription(data.is_subscribed);
        context.setIsVantageSubscription(data.is_vantage_subscribed);
        context.setIsVantage(data.is_vantage);
        setSelectedModule(
          data.is_vantage ? "vantage_module" : "interview_module"
        );
        setSelectedModuleInEdit(
          data.is_vantage ? "vantage_module" : "interview_module"
        );

        switch (data.subscription_type) {
          case "1month":
          case "1month_edu":
            setChecked({
              ...checked,
              "1month": true,
              "3months": false,
              "6months": false,
              "1month_bank": false,
              "3months_bank": false,
              "6months_bank": false,
              "year_special": false,
              "academic_special": false,
              "year_special_bank": false,
              "academic_special_bank": false,
            });
            break;
          case "3months":
          case "3months_edu":
            setChecked({
              ...checked,
              "1month": false,
              "3months": true,
              "6months": false,
              "1month_bank": false,
              "3months_bank": false,
              "6months_bank": false,
              "year_special": false,
              "academic_special": false,
              "year_special_bank": false,
              "academic_special_bank": false,
            });
            break;
          case "6months":
          case "6months_edu":
            setChecked({
              ...checked,
              "1month": false,
              "3months": false,
              "6months": true,
              "1month_bank": false,
              "3months_bank": false,
              "6months_bank": false,
              "year_special": false,
              "academic_special": false,
              "year_special_bank": false,
              "academic_special_bank": false,
            });
            break;
          case "1month_bank":
          case "1month_bank_edu":
            setChecked({
              ...checked,
              "1month": false,
              "3months": false,
              "6months": false,
              "1month_bank": true,
              "3months_bank": false,
              "6months_bank": false,
              "year_special": false,
              "academic_special": false,
              "year_special_bank": false,
              "academic_special_bank": false,
            });
            break;
          case "3months_bank":
          case "3months_bank_edu":
            setChecked({
              ...checked,
              "1month": false,
              "3months": false,
              "6months": false,
              "1month_bank": false,
              "3months_bank": true,
              "6months_bank": false,
              "year_special": false,
              "academic_special": false,
              "year_special_bank": false,
              "academic_special_bank": false,
            });
            break;
          case "6months_bank":
          case "6months_bank_edu":
            setChecked({
              ...checked,
              "1month": false,
              "3months": false,
              "6months": false,
              "1month_bank": false,
              "3months_bank": false,
              "6months_bank": true,
              "year_special": false,
              "academic_special": false,
              "year_special_bank": false,
              "academic_special_bank": false,
            });
            break;
          case "year_special":
            setChecked({
              ...checked,
              "1month": false,
              "3months": false,
              "6months": false,
              "1month_bank": false,
              "3months_bank": false,
              "6months_bank": false,
              "year_special": true,
              "academic_special": false,
              "year_special_bank": false,
              "academic_special_bank": false,
            });
            break;
          case "academic_special":
            setChecked({
              ...checked,
              "1month": false,
              "3months": false,
              "6months": false,
              "1month_bank": false,
              "3months_bank": false,
              "6months_bank": false,
              "year_special": false,
              "academic_special": true,
              "year_special_bank": false,
              "academic_special_bank": false,
            });
            break;
          case "year_special_bank":
            setChecked({
              ...checked,
              "1month": false,
              "3months": false,
              "6months": false,
              "1month_bank": false,
              "3months_bank": false,
              "6months_bank": false,
              "year_special": false,
              "academic_special": false,
              "year_special_bank": true,
              "academic_special_bank": false,
            });
            break;
          case "academic_special_bank":
            setChecked({
              ...checked,
              "1month": false,
              "3months": false,
              "6months": false,
              "1month_bank": false,
              "3months_bank": false,
              "6months_bank": false,
              "year_special": false,
              "academic_special": false,
              "year_special_bank": false,
              "academic_special_bank": true,
            });
            break;
        }

        switch (data.vantage_subscription_type) {
          case "3month":
            setVantageChecked({
              ...vantageChecked,
              "3month": true,
              "3month_bank": false,
              "year": false,
              "year_bank": false,
            });
            break;
          case "3month_bank":
            setVantageChecked({
              ...vantageChecked,
              "3month": false,
              "3month_bank": true,
              "year": false,
              "year_bank": false,
            });
            break;
          case "year":
            setVantageChecked({
              ...vantageChecked,
              "3month": false,
              "3month_bank": false,
              "year": true,
              "year_bank": false,
            });
            break;
          case "year_bank":
            setVantageChecked({
              ...vantageChecked,
              "3month": false,
              "3month_bank": false,
              "year": false,
              "year_bank": true,
            });
            break;
        }
      }
    } catch (error) {
      // console.log(error)
      const statusCode = error.response.status;
      if (statusCode === 400) {
        setError({ ...error, submit: "User does not exist." });
      }
      if (statusCode === 401) {
        context.setIsLoggedIn(false);
        localStorage.clear();
        navigate("/");
      }
    }
  }

  useEffect(() => {
    setLoading(true);
    loadUserInfo();
    setLoading(false);
    context.setVantageThemeData([])
  }, []);

  useEffect(() => {
    // console.log(currentURL)
    if (currentURL !== "/settings") {
      toast.warn("Must be subscribed to view content.");
    }
  }, [currentURL]);

  const handlePasswordReset = async () => {
    setSuccess(false);
    setError("");
    if (
      !password.newValue ||
      !password.confirmValue ||
      password.newValue !== password.confirmValue
    ) {
      setError(
        "Error: The confirm password doesn't match with the new password!"
      );
      return;
    }
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token} `,
    };
    const formData = {
      password: password.newValue,
    };
    try {
      setLoading(true);
      if (token) {
        await axios.post(
          `${process.env.REACT_APP_QA_API}/reset-password/${token}/${email}`,
          formData,
          { headers }
        );
        setLoading(false);
        setSuccess(true);
        setError("");
        setPassword({
          newValue: "",
          confirmValue: "",
        });
        context.setIsLoggedIn(false);
        navigate("/");
        localStorage.clear();
        context.setLoginModal(true);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      if (error.response.status === 401) {
        context.setIsLoggedIn(false);
        localStorage.clear();
        navigate("/");
      }
      setSuccess(false);
      setError("Some error occured. Please reload your page.");
    }
  };

  const handleSubscription = async () => {
    const stripe = await stripePromise;
    try {
      const { data, status } = await axios.post(
        `${process.env.REACT_APP_QA_API}/payments/create-checkout-session`,
        { "subscriptionType": subscriptionType },
        { headers }
      );
      if (status == 200) {
        stripe.redirectToCheckout({ sessionId: data.sessionId });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleVantageSubscription = async () => {
    const stripe = await stripePromise;
    try {
      const { data, status } = await axios.post(
        `${process.env.REACT_APP_QA_API}/payments/create-vantage-checkout-session`,
        { "subscriptionType": vantageSubscriptionType },
        { headers }
      );
      if (status == 200) {
        stripe.redirectToCheckout({ sessionId: data.sessionId });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const cancelSubscription = async () => {
    setShowCancelSubscriptionModal(true);
  };

  const confirmCancelSubscription = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token} `,
      };

      if (selectedModule === "interview_module") {
        const { data, status } = await axios.delete(
          `${process.env.REACT_APP_QA_API}/payments/cancel-subscription/${userInfo.subscription_id}`,
          { headers }
        );

        if (status === 200) {
          setUserInfo({ ...userInfo, is_subscribed: false });
          localStorage.setItem("is_subscribed", false);
          context.setIsSubscription(false);
          setIsAcceptedTerms(false);
          setChecked({
            "1month": false,
            "3months": false,
            "6months": false,
            "1month_bank": false,
            "3months_bank": false,
            "6months_bank": false,
            "year_special": false,
            "academic_special": false,
            "year_special_bank": false,
            "academic_special_bank": false,
          });
          setSubscriptionType(null);
        }
      } else {
        const { data, status } = await axios.delete(
          `${process.env.REACT_APP_QA_API}/payments/cancel-vantage-subscription/${userInfo.vantage_subscription_id}`,
          { headers }
        );

        if (status === 200) {
          setUserInfo({ ...userInfo, is_vantage_subscribed: false });
          localStorage.setItem("is_vantage_subscribed", false);
          context.setIsVantageSubscription(false);
          setIsAcceptedTerms(false);
          setVantageChecked({
            "3month": false,
            "3month_bank": false,
            "year": false,
            "year_bank": false,
          });
          setVantageSubscriptionType(null);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubscriptionTypeChange = async (checkId) => {
    if (checkId === "1month") {
      if (is_edu) {
        setSubscriptionType("1month_edu");
      } else {
        setSubscriptionType("1month");
      }
      setChecked({
        ...checked,
        "1month": !checked["1month"],
        "3months": false,
        "6months": false,
        "1month_bank": false,
        "3months_bank": false,
        "6months_bank": false,
        "year_special": false,
        "academic_special": false,
        "year_special_bank": false,
        "academic_special_bank": false,
      });
    }
    if (checkId === "3months") {
      if (is_edu) {
        setSubscriptionType("3months_edu");
      } else {
        setSubscriptionType("3months");
      }
      setChecked({
        ...checked,
        "1month": false,
        "3months": !checked["3months"],
        "6months": false,
        "1month_bank": false,
        "3months_bank": false,
        "6months_bank": false,
        "year_special": false,
        "academic_special": false,
        "year_special_bank": false,
        "academic_special_bank": false,
      });
    }
    if (checkId === "6months") {
      if (is_edu) {
        setSubscriptionType("6months_edu");
      } else {
        setSubscriptionType("6months");
      }
      setChecked({
        ...checked,
        "1month": false,
        "3months": false,
        "6months": !checked["6months"],
        "1month_bank": false,
        "3months_bank": false,
        "6months_bank": false,
        "year_special": false,
        "academic_special": false,
        "year_special_bank": false,
        "academic_special_bank": false,
      });
    }

    if (checkId === "1month_bank") {
      if (is_edu) {
        setSubscriptionType("1month_bank_edu");
      } else {
        setSubscriptionType("1month_bank");
      }
      setChecked({
        ...checked,
        "1month": false,
        "3months": false,
        "6months": false,
        "1month_bank": !checked["1month_bank"],
        "3months_bank": false,
        "6months_bank": false,
        "year_special": false,
        "academic_special": false,
        "year_special_bank": false,
        "academic_special_bank": false,
      });
    }
    if (checkId === "3months_bank") {
      if (is_edu) {
        setSubscriptionType("3months_bank_edu");
      } else {
        setSubscriptionType("3months_bank");
      }
      setChecked({
        ...checked,
        "1month": false,
        "3months": false,
        "6months": false,
        "1month_bank": false,
        "3months_bank": !checked["3months_bank"],
        "6months_bank": false,
        "year_special": false,
        "academic_special": false,
        "year_special_bank": false,
        "academic_special_bank": false,
      });
    }
    if (checkId === "6months_bank") {
      if (is_edu) {
        setSubscriptionType("6months_bank_edu");
      } else {
        setSubscriptionType("6months_bank");
      }
      setChecked({
        ...checked,
        "1month": false,
        "3months": false,
        "6months": false,
        "1month_bank": false,
        "3months_bank": false,
        "6months_bank": !checked["6months_bank"],
        "year_special": false,
        "academic_special": false,
        "year_special_bank": false,
        "academic_special_bank": false,
      });
    }
    if (checkId === "year_special") {
      setSubscriptionType("year_special");
      setChecked({
        ...checked,
        "1month": false,
        "3months": false,
        "6months": false,
        "1month_bank": false,
        "3months_bank": false,
        "6months_bank": false,
        "year_special": !checked["year_special"],
        "academic_special": false,
        "year_special_bank": false,
        "academic_special_bank": false,
      });
    }
    if (checkId === "academic_special") {
      setSubscriptionType("academic_special");
      setChecked({
        ...checked,
        "1month": false,
        "3months": false,
        "6months": false,
        "1month_bank": false,
        "3months_bank": false,
        "6months_bank": false,
        "year_special": false,
        "academic_special": !checked["academic_special"],
        "year_special_bank": false,
        "academic_special_bank": false,
      });
    }
    if (checkId === "year_special_bank") {
      setSubscriptionType("year_special_bank");
      setChecked({
        ...checked,
        "1month": false,
        "3months": false,
        "6months": false,
        "1month_bank": false,
        "3months_bank": false,
        "6months_bank": false,
        "year_special": false,
        "academic_special": false,
        "year_special_bank": !checked["year_special_bank"],
        "academic_special_bank": false,
      });
    }
    if (checkId === "academic_special_bank") {
      setSubscriptionType("academic_special_bank");
      setChecked({
        ...checked,
        "1month": false,
        "3months": false,
        "6months": false,
        "1month_bank": false,
        "3months_bank": false,
        "6months_bank": false,
        "year_special": false,
        "academic_special": false,
        "year_special_bank": false,
        "academic_special_bank": !checked["academic_special_bank"],
      });
    }
  };

  const handleVantageSubscriptionTypeChange = async (checkId) => {
    if (checkId === "3month") {
      setVantageSubscriptionType("3month");
      setVantageChecked({
        ...vantageChecked,
        "3month": !vantageChecked["3month"],
        "3month_bank": false,
        "year": false,
        "year_bank": false,
      });
    }
    if (checkId === "3month_bank") {
      setVantageSubscriptionType("3month_bank");
      setVantageChecked({
        ...vantageChecked,
        "3month": false,
        "3month_bank": !vantageChecked["3month_bank"],
        "year": false,
        "year_bank": false,
      });
    }
    if (checkId === "year") {
      setVantageSubscriptionType("year");
      setVantageChecked({
        ...vantageChecked,
        "3month": false,
        "3month_bank": false,
        "year": !vantageChecked["year"],
        "year_bank": false,
      });
    }
    if (checkId === "year_bank") {
      setVantageSubscriptionType("year_bank");
      setVantageChecked({
        ...vantageChecked,
        "3month": false,
        "3month_bank": false,
        "year": false,
        "year_bank": !vantageChecked["year_bank"],
      });
    }
  };

  const confirmSubscription = async () => {
    if (selectedModule === "interview_module") {
      if (subscriptionType === null) {
        setCardError("Please select subscription type.");
        return;
      }
    } else {
      if (vantageSubscriptionType === null) {
        setCardError("Please select subscription type.");
        return;
      }
    }

    if (email === "" || email === undefined || email === null) {
      setCardError("Valid card information required to subscribe.");
      return;
    }
    if (!isAcceptedTerms) {
      setShowTermsModal(true);
    } else {
      if (selectedModule === "interview_module") {
        handleSubscription();
      } else {
        handleVantageSubscription();
      }
    }
  };

  const updateProfile = async () => {
    if (!userInfo.email) {
      setProfileError({ ...profileError, email: "Email is required" });
      return;
    }
    if (!userInfo.username) {
      setProfileError({ ...profileError, username: "Username is required" });
      return;
    }
    if (!userInfo.linkedin) {
      setProfileError({ ...profileError, linkedin: "Linkedin is required" });
      return;
    }
    try {
      const { data, status } = await axios.put(
        `${process.env.REACT_APP_QA_API}/user-profile/${userId}`,
        userInfo,
        { headers }
      );
      if (status === 200) {
        setIsInEdit(false);
        setProfileError({
          email: "",
          username: "",
          linkedin: "",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setProfileError({
      email: "",
      username: "",
      linkedin: "",
    });
  }, [isInEdit]);

  const handleModuleChange = (e) => {
    setSelectedModuleInEdit(e.target.value);
  };

  const confirmModuleChange = async () => {
    try {
      const { data, status } = await axios.put(
        `${process.env.REACT_APP_QA_API}/user-profile/${userId}`,
        { is_vantage: selectedModuleInEdit === "vantage_module" ? true : false },
        { headers }
      );
      if (status === 200) {
        navigate("/settings");
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div
        className={`w-full ${context.isVantage ? '' : 'h-screen'} bg-gray-g5 flex flex-col py-3 md:py-3 md:px-6 overflow-y-scroll`}
      >
        <div className="w-full h-screen">
          <div className="my-2 px-2 text-2xl font-bold ">Profile settings</div>
          <div className="flex flex-col gap-6 py-6 px-2  md:px-20  md:-mx-6 bg-white">
            {loading && <div className="h-screen text-white">loading...</div>}
            {success && (
              <div className="text-green-g1">
                Password successfully changed! Please login again!
              </div>
            )}
            {error && <div className="text-red-r1">{error}</div>}
            <div className="w-full flex flex-col md:flex-row justify-between gap-8">
              <div className="md:w-1/2">
                <div className="py-2 text-sm required">
                  Display name for discussion forums
                </div>
                <input
                  type="text"
                  placeholder="Type username..."
                  className="w-full py-2 px-6 ring-1 ring-gray-g6 text-sm focus:outline-none rounded-full "
                  value={userInfo.username === null ? "" : userInfo.username}
                  disabled={isInEdit ? "" : "disabled"}
                  onChange={(e) =>
                    setUserInfo({ ...userInfo, username: e.target.value })
                  }
                />
                {profileError.username && (
                  <div className="text-red-r1 text-sm">
                    {profileError.username}
                  </div>
                )}
              </div>
              <div className="md:w-1/2">
                <div className="py-2 text-sm required">Email</div>
                <input
                  type="email"
                  placeholder="Type email address..."
                  className="w-full py-2 px-6 ring-1 ring-gray-g6 text-sm focus:outline-none rounded-full "
                  value={email}
                  disabled={isInEdit ? "" : "disabled"}
                  onChange={(e) =>
                    setUserInfo({ ...userInfo, email: e.target.value })
                  }
                />
                {profileError.email && (
                  <div className="text-red-r1 text-sm">
                    {profileError.email}
                  </div>
                )}
              </div>
            </div>
            <div className="w-full flex flex-col md:flex-row justify-between gap-8">
              <div className="md:w-1/2">
                <div className="py-2 text-sm required">Linkedin</div>
                <input
                  type="text"
                  placeholder="Type Linkedin profile URL"
                  className="w-full py-2 px-6 ring-1 ring-gray-g6 text-sm focus:outline-none rounded-full "
                  value={userInfo.linkedin === null ? "" : userInfo.linkedin}
                  disabled={isInEdit ? "" : "disabled"}
                  onChange={(e) =>
                    setUserInfo({ ...userInfo, linkedin: e.target.value })
                  }
                />
                {profileError.linkedin && (
                  <div className="text-red-r1 text-sm">
                    {profileError.linkedin}
                  </div>
                )}
              </div>
              <div className="md:w-1/2">
                <div className="py-2 text-sm">Phone Number</div>
                <input
                  type="email"
                  placeholder="Type phone number..."
                  className="w-full py-2 px-6 ring-1 ring-gray-g6 text-sm focus:outline-none rounded-full "
                  value={userInfo.phone === null ? "" : userInfo.phone}
                  disabled={isInEdit ? "" : "disabled"}
                  onChange={(e) =>
                    setUserInfo({ ...userInfo, phone: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="w-full flex flex-col md:flex-row justify-between gap-8">
              <div className="md:w-1/2">
                <div className="py-2 text-sm">{"Desired job cities"}</div>
                <input
                  type="text"
                  placeholder="Type desired job cities..."
                  className="w-full py-2 px-6 ring-1 ring-gray-g6 text-sm focus:outline-none rounded-full "
                  value={
                    userInfo.job_cities === null ? "" : userInfo.job_cities
                  }
                  disabled={isInEdit ? "" : "disabled"}
                  onChange={(e) =>
                    setUserInfo({ ...userInfo, job_cities: e.target.value })
                  }
                />
              </div>
              <div className="md:w-1/2">
                <div className="py-2 text-sm">Keywords for recruiters</div>
                <textarea
                  type="text"
                  placeholder="Add key words..."
                  className="w-full py-2 px-6 ring-1 ring-gray-g6 text-sm focus:outline-none rounded-md"
                  value={userInfo.key_words === null ? "" : userInfo.key_words}
                  disabled={isInEdit ? "" : "disabled"}
                  onChange={(e) =>
                    setUserInfo({ ...userInfo, key_words: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="w-full flex flex-col md:flex-row justify-between gap-8">
              <div className="md:w-1/2 flex">
                <input
                  checked={userInfo.block_email_notification}
                  type="checkbox"
                  onChange={() =>
                    setUserInfo({
                      ...userInfo,
                      block_email_notification:
                        !userInfo.block_email_notification,
                    })
                  }
                  disabled={isInEdit ? "" : "disabled"}
                />
                <div className="py-2 text-sm mx-2">
                  {"Block email notification for new discussions and answers."}
                </div>
              </div>
              <div className="md:w-1/2"></div>
            </div>
            {isInEdit ? (
              <div className="py-2 flex justify-end px-2 md:px-14">
                <div
                  className="px-6 py-2 mx-2 bg-green-g1 rounded-full font-semibold text-white cursor-pointer"
                  onClick={() => updateProfile()}
                >
                  Save
                </div>
                <div
                  className="px-6 py-2 mx-2 bg-green-g1 rounded-full font-semibold text-white cursor-pointer"
                  onClick={() => setIsInEdit(false)}
                >
                  Cancel
                </div>
              </div>
            ) : (
              <div className="py-2 flex justify-end px-2 md:px-14">
                <div
                  className="px-6 py-2 bg-green-g1 rounded-full font-semibold text-white cursor-pointer"
                  onClick={() => setIsInEdit(true)}
                >
                  Edit profile
                </div>
              </div>
            )}
          </div>
          <div className="my-2 px-2 text-2xl font-bold ">Change Password</div>
          <div className="flex flex-col gap-6 py-6 px-2  md:px-20  md:-mx-6 bg-white">
            <div className="w-full flex flex-col md:flex-row justify-between gap-8">
              <div className="md:w-1/2">
                <div className="py-2 text-sm required">New Password</div>
                <div className="flex">
                  <input
                    type={showNewPassword ? "text" : "password"}
                    placeholder="Type here..."
                    className="py-2 px-6 ring-1 ring-gray-g6 text-sm focus:outline-none rounded-full w-full"
                    value={password.newValue}
                    onChange={(e) =>
                      setPassword({ ...password, newValue: e.target.value })
                    }
                  />
                  <img
                    src={Eye}
                    alt="ShowPassword"
                    className="w-6 -ml-10 cursor-pointer"
                    onClick={() => setShowNewPassword(!showNewPassword)}
                  />
                </div>
              </div>

              <div className="md:w-1/2">
                <div className="py-2 text-sm required">Confirm Password</div>
                <div className="flex">
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    placeholder="Type here..."
                    className="py-2 px-6 ring-1 ring-gray-g6 text-sm focus:outline-none rounded-full w-full"
                    value={password.confirmValue}
                    onChange={(e) =>
                      setPassword({ ...password, confirmValue: e.target.value })
                    }
                  />
                  <img
                    src={Eye}
                    alt="ShowPassword"
                    className="w-6 -ml-10 cursor-pointer"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  />
                </div>
              </div>
            </div>
            <div className="py-2 md:py-8 flex justify-end px-2 md:px-14">
              <div
                className="px-6 py-2 bg-green-g1 rounded-full font-semibold text-white cursor-pointer"
                onClick={handlePasswordReset}
              >
                Save Password
              </div>
            </div>
          </div>
          <div className="my-2 px-2 text-2xl font-bold ">Change Platform</div>
          <div className="flex flex-col gap-6 py-6 px-2  md:px-20  md:-mx-6 bg-white">
            <div className="w-full flex flex-col md:flex-row justify-between gap-8">
              <div className="md:w-1/2">
                <div className="flex">
                  <input
                    type="radio"
                    className="py-2 px-6 text-sm focus:outline-none rounded-full"
                    value="interview_module"
                    checked={selectedModuleInEdit === "interview_module"}
                    onChange={(e) => handleModuleChange(e)}
                  />
                  <div className="ml-2 py-2 text-sm">Interview</div>
                </div>
              </div>

              <div className="md:w-1/2">
                <div className="flex">
                  <input
                    type="radio"
                    className="py-2 px-6 text-sm focus:outline-none rounded-full"
                    value="vantage_module"
                    checked={selectedModuleInEdit === "vantage_module"}
                    onChange={(e) => handleModuleChange(e)}
                  />
                  <div className="ml-2 py-2 text-sm">Vantage</div>
                </div>
              </div>
            </div>
            <div className="py-2 md:py-8 flex justify-end px-2 md:px-14">
              <div
                className="px-6 py-2 bg-green-g1 rounded-full font-semibold text-white cursor-pointer"
                onClick={confirmModuleChange}
              >
                Change
              </div>
            </div>
          </div>
          <div className="my-4 px-2 text-base">
            {
              "A user can only log in from a maximum of two browsers - they can be on different devices. To reset your devices, please contact"
            }
            <a
              href="mailto:support@quantfinanceprep.com"
              className="text-blue-500"
            >
              {" "}
              support@quantFinancePrep.com
            </a>
          </div>
          <div className="my-4 px-2 text-base">
            {
              "If you have a promotion code, please add it during checkout when entering payment method."
            }
          </div>
          <div className="my-2 px-2 text-2xl font-bold ">Subscription</div>
          <div className=" gap-4 py-2 px-2 md:px-14 md:-mx-6 bg-white text-red-r1">
            {cardError}
          </div>
          {selectedModule === "interview_module" ? (
            <>
              <div className="py-0 px-2 md:px-14 md:-mx-6 bg-white">
                Pay by credit card via Stripe: 3.5% additional fees
              </div>
              <div className="flex flex-col md:flex-row items-center gap-14 py-6 pt-2 px-2 md:px-14 md:-mx-6 bg-white">
                <div className="w-3/5">
                  <div
                    className={`py-6 md:px-10 bg-gray-g5 text-center ${
                      checked["1month"] &&
                      context.isSubscription &&
                      "border-dotted border-2 border-green-g1"
                    }`}
                  >
                    <input
                      checked={checked["1month"]}
                      type="checkbox"
                      onChange={() => handleSubscriptionTypeChange("1month")}
                      disabled={context.isSubscription ? true : false}
                    />
                    <div className="text-green-g1 text-2xl font-bold">
                      {"$" + priceOptions["1month"].toLocaleString()}
                    </div>
                    <div className="text-xl">per month</div>
                  </div>
                </div>
                <div className="w-3/5">
                  <div
                    className={`py-6 md:px-10 bg-gray-g5 text-center ${
                      checked["3months"] &&
                      context.isSubscription &&
                      "border-dotted border-2 border-green-g1"
                    }`}
                  >
                    <input
                      checked={checked["3months"]}
                      type="checkbox"
                      onChange={() => handleSubscriptionTypeChange("3months")}
                      disabled={context.isSubscription ? true : false}
                    />
                    <div className="text-green-g1 text-2xl font-bold">
                      {"$" + priceOptions["3months"].toLocaleString()}
                    </div>
                    <div className="text-xl">every 3 months</div>
                  </div>
                </div>
                <div className="w-3/5">
                  <div
                    className={`py-6 md:px-10 bg-gray-g5 text-center ${
                      checked["6months"] &&
                      context.isSubscription &&
                      "border-dotted border-2 border-green-g1"
                    }`}
                  >
                    <input
                      checked={checked["6months"]}
                      type="checkbox"
                      onChange={() => handleSubscriptionTypeChange("6months")}
                      disabled={context.isSubscription ? true : false}
                    />
                    <div className="text-green-g1 text-2xl font-bold">
                      {"$" + priceOptions["6months"].toLocaleString()}
                    </div>
                    <div className="text-xl">every 6 months</div>
                  </div>
                </div>
                {is_edu && (
                  <>
                    <div className="w-3/5">
                      <div
                        className={`py-6 md:px-10 bg-gray-g5 text-center ${
                          checked["year_special"] &&
                          context.isSubscription &&
                          "border-dotted border-2 border-green-g1"
                        }`}
                      >
                        <input
                          checked={checked["year_special"]}
                          type="checkbox"
                          onChange={() =>
                            handleSubscriptionTypeChange("year_special")
                          }
                          disabled={context.isSubscription ? true : false}
                        />
                        <div className="text-green-g1 text-2xl font-bold">
                          {"$" + priceOptions["year_special"].toLocaleString()}
                        </div>
                        <div className="text-xl">1 year</div>
                      </div>
                    </div>
                    <div className="w-3/5">
                      <div
                        className={`py-6 md:px-10 bg-gray-g5 text-center ${
                          checked["academic_special"] &&
                          context.isSubscription &&
                          "border-dotted border-2 border-green-g1"
                        }`}
                      >
                        <input
                          checked={checked["academic_special"]}
                          type="checkbox"
                          onChange={() =>
                            handleSubscriptionTypeChange("academic_special")
                          }
                          disabled={context.isSubscription ? true : false}
                        />
                        <div className="text-green-g1 text-2xl font-bold">
                          {"$" +
                            priceOptions["academic_special"].toLocaleString()}
                        </div>
                        <div className="text-xl">Duration of study</div>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="py-0 px-2 md:px-14 md:-mx-6 bg-white">
                Pay by bank transfer via Stripe: 1% additional fees
              </div>
              <div className="flex flex-col md:flex-row items-center gap-14 py-6 pt-2 px-2 md:px-14 md:-mx-6 bg-white">
                <div className="w-3/5">
                  <div
                    className={`py-6 md:px-10 bg-gray-g5 text-center ${
                      checked["1month_bank"] &&
                      context.isSubscription &&
                      "border-dotted border-2 border-green-g1"
                    }`}
                  >
                    <input
                      checked={checked["1month_bank"]}
                      type="checkbox"
                      onChange={() =>
                        handleSubscriptionTypeChange("1month_bank")
                      }
                      disabled={context.isSubscription ? true : false}
                    />
                    <div className="text-green-g1 text-2xl font-bold">
                      {"$" + priceOptions["1month_bank"].toLocaleString()}
                    </div>
                    <div className="text-xl">per month</div>
                  </div>
                </div>
                <div className="w-3/5">
                  <div
                    className={`py-6 md:px-10 bg-gray-g5 text-center ${
                      checked["3months_bank"] &&
                      context.isSubscription &&
                      "border-dotted border-2 border-green-g1"
                    }`}
                  >
                    <input
                      checked={checked["3months_bank"]}
                      type="checkbox"
                      onChange={() =>
                        handleSubscriptionTypeChange("3months_bank")
                      }
                      disabled={context.isSubscription ? true : false}
                    />
                    <div className="text-green-g1 text-2xl font-bold">
                      {"$" + priceOptions["3months_bank"].toLocaleString()}
                    </div>
                    <div className="text-xl">every 3 months</div>
                  </div>
                </div>
                <div className="w-3/5">
                  <div
                    className={`py-6 md:px-10 bg-gray-g5 text-center ${
                      checked["6months_bank"] &&
                      context.isSubscription &&
                      "border-dotted border-2 border-green-g1"
                    }`}
                  >
                    <input
                      checked={checked["6months_bank"]}
                      type="checkbox"
                      onChange={() =>
                        handleSubscriptionTypeChange("6months_bank")
                      }
                      disabled={context.isSubscription ? true : false}
                    />
                    <div className="text-green-g1 text-2xl font-bold">
                      {"$" + priceOptions["6months_bank"].toLocaleString()}
                    </div>
                    <div className="text-xl">every 6 months</div>
                  </div>
                </div>
                {is_edu && (
                  <>
                    <div className="w-3/5">
                      <div
                        className={`py-6 md:px-10 bg-gray-g5 text-center ${
                          checked["year_special_bank"] &&
                          context.isSubscription &&
                          "border-dotted border-2 border-green-g1"
                        }`}
                      >
                        <input
                          checked={checked["year_special_bank"]}
                          type="checkbox"
                          onChange={() =>
                            handleSubscriptionTypeChange("year_special_bank")
                          }
                          disabled={context.isSubscription ? true : false}
                        />
                        <div className="text-green-g1 text-2xl font-bold">
                          {"$" +
                            priceOptions["year_special_bank"].toLocaleString()}
                        </div>
                        <div className="text-xl">1 year</div>
                      </div>
                    </div>
                    <div className="w-3/5">
                      <div
                        className={`py-6 md:px-10 bg-gray-g5 text-center ${
                          checked["academic_special_bank"] &&
                          context.isSubscription &&
                          "border-dotted border-2 border-green-g1"
                        }`}
                      >
                        <input
                          checked={checked["academic_special_bank"]}
                          type="checkbox"
                          onChange={() =>
                            handleSubscriptionTypeChange(
                              "academic_special_bank"
                            )
                          }
                          disabled={context.isSubscription ? true : false}
                        />
                        <div className="text-green-g1 text-2xl font-bold">
                          {"$" +
                            priceOptions[
                              "academic_special_bank"
                            ].toLocaleString()}
                        </div>
                        <div className="text-xl">Duration of study</div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </>
          ) : (
            <div className="flex flex-col md:flex-row items-center">
              <div className="md:w-1/2 w-full text-center">
                <div className="py-0 px-2 md:px-14 md:-mx-6 bg-white">
                  Pay by credit card via Stripe: 3.5% additional fees
                </div>
                <div className="flex flex-col md:flex-row items-center gap-14 py-6 pt-2 px-2 md:px-14 md:-mx-6 bg-white">
                  <div className="w-3/5 m-auto">
                    <div
                      className={`py-6 md:px-10 bg-gray-g5 text-center ${
                        vantageChecked["3month"] &&
                        context.isVantageSubscription &&
                        "border-dotted border-2 border-green-g1"
                      }`}
                    >
                      <input
                        checked={vantageChecked["3month"]}
                        type="checkbox"
                        onChange={() =>
                          handleVantageSubscriptionTypeChange("3month")
                        }
                        disabled={context.isVantageSubscription ? true : false}
                      />
                      <div className="text-green-g1 text-2xl font-bold">
                        {"$" + vantagePriceOptions["3month"].toLocaleString()}
                      </div>
                      <div className="text-xl">per 3 months</div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col md:flex-row items-center gap-14 py-6 pt-2 px-2 md:px-14 md:-mx-6 bg-white">
                  <div className="w-3/5 m-auto">
                    <div
                      className={`py-6 md:px-10 bg-gray-g5 text-center ${
                        vantageChecked["year"] &&
                        context.isVantageSubscription &&
                        "border-dotted border-2 border-green-g1"
                      }`}
                    >
                      <input
                        checked={vantageChecked["year"]}
                        type="checkbox"
                        onChange={() =>
                          handleVantageSubscriptionTypeChange("year")
                        }
                        disabled={context.isVantageSubscription ? true : false}
                      />
                      <div className="text-green-g1 text-2xl font-bold">
                        {"$" + vantagePriceOptions["year"].toLocaleString()}
                      </div>
                      <div className="text-xl">per year</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="md:w-1/2 w-full text-center">
                <div className="py-0 px-2 md:px-14 md:-mx-6 bg-white">
                  Pay by bank transfer via Stripe: 1% additional fees
                </div>
                <div className="flex flex-col md:flex-row items-center gap-14 py-6 pt-2 px-2 md:px-14 md:-mx-6 bg-white">
                  <div className="w-3/5 m-auto">
                    <div
                      className={`py-6 md:px-10 bg-gray-g5 text-center ${
                        vantageChecked["3month_bank"] &&
                        context.isVantageSubscription &&
                        "border-dotted border-2 border-green-g1"
                      }`}
                    >
                      <input
                        checked={vantageChecked["3month_bank"]}
                        type="checkbox"
                        onChange={() =>
                          handleVantageSubscriptionTypeChange("3month_bank")
                        }
                        disabled={context.isVantageSubscription ? true : false}
                      />
                      <div className="text-green-g1 text-2xl font-bold">
                        {"$" +
                          vantagePriceOptions["3month_bank"].toLocaleString()}
                      </div>
                      <div className="text-xl">per 3 months</div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col md:flex-row items-center gap-14 py-6 pt-2 px-2 md:px-14 md:-mx-6 bg-white">
                  <div className="w-3/5 m-auto">
                    <div
                      className={`py-6 md:px-10 bg-gray-g5 text-center ${
                        vantageChecked["year_bank"] &&
                        context.isVantageSubscription &&
                        "border-dotted border-2 border-green-g1"
                      }`}
                    >
                      <input
                        checked={vantageChecked["year_bank"]}
                        type="checkbox"
                        onChange={() =>
                          handleVantageSubscriptionTypeChange("year_bank")
                        }
                        disabled={context.isVantageSubscription ? true : false}
                      />
                      <div className="text-green-g1 text-2xl font-bold">
                        {"$" +
                          vantagePriceOptions["year_bank"].toLocaleString()}
                      </div>
                      <div className="text-xl">per year</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="py-2 md:py-8 flex justify-end px-2 md:px-14">
            {(context.isSubscription && selectedModule === "interview_module") || (context.isVantageSubscription && selectedModule === "vantage_module") ? (
              <>
                <div
                  className="px-6 py-2 bg-green-g1 mx-2 rounded-full font-semibold text-white cursor-pointer"
                  onClick={(e) => cancelSubscription()}
                >
                  Change Subscription
                </div>
              </>
            ) : (
              <div
                className="px-6 py-2 bg-green-g1 rounded-full font-semibold text-white cursor-pointer"
                onClick={(e) => confirmSubscription()}
              >
                Choose Subscription
              </div>
            )}
          </div>
        </div>
      </div>
      {showTermsModal && (
        <TermsAndConditionsModal
          setShowModal={setShowTermsModal}
          setIsAcceptedTerms={setIsAcceptedTerms}
          handleSubscription={
            selectedModule === "interview_module"
              ? handleSubscription
              : handleVantageSubscription
          }
          acceptable={true}
        />
      )}
      {showCancelSubscriptionModal && (
        <CancelSubscriptionConfirmModal
          setShowCancelSubscriptionModal={setShowCancelSubscriptionModal}
          confirmCancelSubscription={confirmCancelSubscription}
        />
      )}
      {context.showLegalDisclaimerModal && <LegalDisclaimerModal />}
      <ToastContainer />
    </>
  );
};

export default Setting;
